import { useState, useEffect } from 'react';


export default function Main_Page() {   
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoaded(true);
      }, 1000); // Delay of 1 second before the image fades in
      return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);

  return (
    <div>
      <img 
        src="./BG Image.jpg" 
        alt="Background Image" 
        className="img-fluid" 
        style={{
          width: '100%', 
          height: '100vh', 
          position: 'absolute', 
          zIndex: -1, 
          opacity: isLoaded ? 1 : 0, 
          transition: 'opacity 1.5s ease-in-out'
        }} 
      />
      <h1>HI</h1>
    </div>

  );
}